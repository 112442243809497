<template>
	<button
		v-if="isPlaybackSpeedEnabled || isQualitySettingsEnabled"
		ref="settingsMenuButton"
		v-tooltip="tooltip"
		class="player-controls__button"
		@click.stop="handleClick"
	>
		<Icon
			icon-name="settings-gear"
			:size="controlSize"
		/>
		<div
			ref="settingsMenu"
			class="player-controls__menu -settings"
			:class="{ 'is-visible': showMenu }"
		>
			<PlayerControlsSettingsMenu
				v-if="showMenu"
				ref="playerControlsSettingsMenu"
				:size="controlSize"
				v-bind="menuProps"
				@set-quality="handleSetQuality"
				@set-speed="handleSetSpeed"
				@close="handleClose"
			/>
		</div>
	</button>
</template>

<script>
import Icon from '@shared/components/Icon.vue';
import PlayerControlsSettingsMenu from './PlayerControlsSettingsMenu.vue';
import { VTooltip } from 'v-tooltip';

export default {
	name:       'SettingsButton',
	components: {
		Icon,
		PlayerControlsSettingsMenu,
	},
	directives: {
		tooltip: VTooltip,
	},
	props: {
		isPlaybackSpeedEnabled: {
			type:    Boolean,
			default: false,
		},
		isQualitySettingsEnabled: {
			type:    Boolean,
			default: false,
		},
		controlSize: {
			type:     String,
			required: true,
		},
		isMobileWebApp: {
			type:    Boolean,
			default: false,
		},
		isTouchDevice: {
			type:    Boolean,
			default: false,
		},
		qualityLevels: {
			type:    Array,
			default: () => [],
		},
		currentQualityId: {
			type:    Number,
			default: 0,
		},
		speedOptions: {
			type:     Array,
			required: true,
		},
		currentSpeed: {
			type:    Number,
			default: 1,
		},
	},
	data() {
		return {
			showMenu: false,
		};
	},
	computed: {
		menuProps() {
			const currentSpeed = this.speedOptions.find((speed) => speed.value === this.currentSpeed);

			return {
				variant:                  this.isMobileWebApp ? 'mobile' : 'default',
				qualityOptions:           this.qualityLevels,
				currentQualityId:         this.currentQualityId,
				speedOptions:             this.speedOptions,
				currentSpeedId:           currentSpeed?.id,
				isPlaybackSpeedEnabled:   this.isPlaybackSpeedEnabled,
				isQualitySettingsEnabled: this.isQualitySettingsEnabled,
			};
		},
		tooltip() {
			if (this.isTouchDevice || this.showMenu) return {};

			return {
				content: this.$I18n.trans('commons.settings'),
				classes: 'tooltip',
				trigger: 'hover',
			};
		},
	},
	methods: {
		handleClick(event) {
			if (this.$refs.settingsMenu?.contains(event.target)) {
				return;
			}
			this.showMenu = !this.showMenu;
			this.$emit('menu-toggle', this.showMenu);

			if (this.showMenu) {
				this.$emit('opened');

				const interval = setInterval(() => {
					if (this.showMenu) {
						this.$emit('still-open');
					} else {
						clearInterval(interval);
					}
				}, 2000);
			}

			if (this.$refs.playerControlsSettingsMenu) {
				this.$refs.playerControlsSettingsMenu.resetToMainMenu();
			}
		},
		handleClose() {
			this.showMenu = false;
			this.$emit('menu-toggle', false);
			this.$emit('close');
		},
		handleSetSpeed(id) {
			const speed = this.speedOptions.find((v) => v.id === id);
			this.$emit('set-speed', speed.value);
		},
		handleSetQuality(id) {
			this.$emit('set-quality', id);
		},
		closeMenu() {
			if (this.showMenu) {
				this.showMenu = false;
				this.$emit('menu-toggle', false);
				this.$emit('close');
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.player-controls__button {
	position: relative;
	display: flex;
	color: var(--color-white);
	line-height: 1;
	cursor: pointer;

	:deep(.player-controls.-dark) & {
		color: var(--color-black);
	}
}

.player-controls__menu {
	position: absolute;
	display: flex;
	flex-direction: column;
	visibility: hidden;
	opacity: 0;
	transition: visibility 0.2s, opacity 0.2s ease-in-out;
	text-align: center;
	bottom: 32px;
	right: -16px;

	&.is-visible {
		visibility: visible;
		opacity: 1;
	}
}
</style>
