<template>
	<div
		class="volume-control"
		:class="[ `-size-${size}`, { 'is-dragging': isDraggingVolume, '-dark': darkTheme } ]"
	>
		<button
			class="volume-control__button"
			@click.stop="handleMuteToggle"
		>
			<Icon
				:icon-name="volumeIcon"
				:size="size"
			/>
		</button>
		<div
			v-if="showVolumeSelector"
			ref="volumeSelector"
			class="volume-control__selector"
			@mousedown="handleVolumeMouseDown"
			@touchstart="handleVolumeMouseDown"
			@click.stop="handleVolumeClick"
		>
			<div class="volume-control__selector-box">
				<div
					class="volume-control__cursor"
					:style="volumeCursorStyle"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Icon from '@shared/components/Icon.vue';
import { throttle } from '@shared/utils/throttle';

export default {
	name:       'VolumeControl',
	components: {
		Icon,
	},
	props: {
		isMuted: {
			type:    Boolean,
			default: false,
		},
		volumeLevel: {
			type:    Number,
			default: 50,
		},
		showVolumeSelector: {
			type:    Boolean,
			default: true,
		},
		size: {
			type:      String,
			default:   'lg',
			validator: (value) => ['sm', 'md', 'lg'].includes(value),
		},
		darkTheme: {
			type:    Boolean,
			default: false,
		},
	},
	data: () => {
		return {
			isDraggingVolume: false,
		};
	},
	computed: {
		volumeIcon() {
			if (this.isMuted || this.volumeLevel === 0) {
				return 'sound-off';
			}
			if (this.volumeLevel < 33) {
				return 'sound-quiet';
			}
			if (this.volumeLevel < 66) {
				return 'sound-on';
			}
			return 'volume-loud';
		},
		volumeCursorStyle() {
			return {
				width: `${this.isMuted ? 0 : this.volumeLevel}%`,
			};
		},
	},
	created() {
		this.throttleVolumeMouseMove = throttle(this.handleVolumeClick, 10);
	},
	methods: {
		handleMuteToggle() {
			this.$emit('mute-toggle');
			if (this.isMuted) {
				this.setMute(false);
			} else {
				this.setMute(true);
			}
		},
		handleVolumeMouseDown(_) {
			this.isDraggingVolume = true;
			document.addEventListener('mousemove', this.handleVolumeMouseMove);
			document.addEventListener('mouseup', this.handleVolumeMouseUp);
			document.addEventListener('touchmove', this.handleVolumeMouseMove);
			document.addEventListener('touchend', this.handleVolumeMouseUp);
		},
		handleVolumeMouseUp() {
			this.isDraggingVolume = false;
			document.removeEventListener('mousemove', this.handleVolumeMouseMove);
			document.removeEventListener('mouseup', this.handleVolumeMouseUp);
			document.removeEventListener('touchmove', this.handleVolumeMouseMove);
			document.removeEventListener('touchend', this.handleVolumeMouseUp);
		},
		handleVolumeMouseMove(e) {
			this.throttleVolumeMouseMove(e);
		},
		handleVolumeClick(e) {
			const ref = this.$refs.volumeSelector;
			const x = e.touches ? e.touches[0].clientX : e.clientX;
			const rect = ref.getBoundingClientRect();
			const val = Math.min(((x - rect.x) / rect.width) * 100, 100);

			this.setVolume(val);
		},
		setMute(value) {
			this.$emit('set-mute', value);
		},
		setVolume(value) {
			const clampedValue = Math.max(0, Math.min(value, 100));
			this.$emit('set-volume', clampedValue);
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.volume-control {
	display: inline-flex;
	align-items: center;
	color: var(--color-white);

	&.-dark {
		color: var(--color-black);
	}
}

.volume-control__button {
	margin: 0;
	line-height: 0;
	cursor: pointer;
}

.volume-control__selector {
	width: 100%;
	height: 100%;
	opacity: 0;
	width: 0;
	transition: all 0.5s ease-out; // stylelint-disable-line declaration-property-value-disallowed-list
	padding: 8px 0;
	cursor: pointer;
	touch-action: none;

	.volume-control:hover &,
	.volume-control.is-dragging & {
		width: 200px;
		margin-left: 10px;
		opacity: 1;
	}
}

.volume-control__selector-box {
	position: relative;
	transition: all 0.5s ease-out; // stylelint-disable-line declaration-property-value-disallowed-list
	height: 2px;
	border-radius: 4px;
	background-color: var(--color-black);
	border: 1px solid var(--color-grey-850);
	cursor: pointer;

	.volume-control.-dark & {
		background-color: var(--color-soft-white);
		border-color: var(--color-grey-300);
	}
}

.volume-control__cursor {
	background-color: var(--color-soft-white);
	height: 2px;
	position: relative;
	border-radius: 4px;

	&::after {
		content: '';
		height: 12px;
		width: 12px;
		background-color: var(--color-white);
		display: block;
		z-index: 1;
		position: absolute;
		right: -6px;
		top: -5px;
		border-radius: 50%;
	}

	.volume-control.-dark & {
		background-color: var(--color-black);

		&::after {
			background-color: var(--color-black);
		}
	}
}

</style>
