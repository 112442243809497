<template>
	<div class="view-multi-format">
		<MultiFormatTool
			v-if="assetsAvailable"
			:event-id="eventId"
			:tag-time="tagTime"
			:video-id="videoId"
			:hls-url="hlsUrl"
			:logos="logos"
			:is-community-cropping="isCommunityCropping"
		/>
		<div v-else>
			{{ $I18n.trans('event.multiformat_editor.messages.tool_not_available') }}
		</div>
	</div>
</template>

<script>
import MultiFormatTool from '@shared/components/MultiFormatTool/MultiFormatTool.vue';
import { VideoserviceFileStatus } from '@shared/enums/VideoserviceFileStatus';

export default {
	name:       'ViewMultiFormat',
	components: { MultiFormatTool },
	props:      {
		eventId: {
			type:     Number,
			required: true,
		},
		tagTime: {
			type:    Object,
			default: null,
		},
		hlsUrl: {
			type:    String,
			default: null,
		},
		videoId: {
			type:    String,
			default: null,
		},
		videoStatus: {
			type:    String,
			default: VideoserviceFileStatus.PENDING,
		},
		logos: {
			type:    Array,
			default: () => [],
		},
		isCommunityCropping: {
			type:    Boolean,
			default: false,
		},
	},
	computed: {
		assetsAvailable() {
			return this.videoStatus === VideoserviceFileStatus.AVAILABLE && this.hlsUrl;
		},
	},
};

</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.view-multi-format {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	color: var(--color-soft-white);
	background-color: var(--color-black);
	padding-top: $sp6;

	@include mobile-landscape {
		padding-top: 0;
	}

	@include mobile-portrait {
		padding-top: 0;
	}
}
</style>
