<template>
	<button
		v-if="forceShow || audioTracks.length > 1"
		ref="audioTracksMenuButton"
		v-tooltip="tooltip"
		class="player-controls__button"
		@click.stop="handleClick"
	>
		<Icon
			icon-name="headset"
			:size="controlSize"
		/>
		<div
			ref="audioTracksMenu"
			class="player-controls__menu -audio"
			:class="{ 'is-visible': showMenu }"
		>
			<PlayerControlsSettingsMenu
				v-if="showMenu"
				ref="playerControlsAudioTracksMenu"
				:size="controlSize"
				v-bind="menuProps"
				@set-audio-track="(value) => $emit('set-audio-track', value)"
				@close="handleClose"
			/>
		</div>
	</button>
</template>

<script>
import Icon from '@shared/components/Icon.vue';
import PlayerControlsSettingsMenu from './PlayerControlsSettingsMenu.vue';
import { VTooltip } from 'v-tooltip';

export default {
	name:       'AudioTracksButton',
	components: {
		Icon,
		PlayerControlsSettingsMenu,
	},
	directives: {
		tooltip: VTooltip,
	},
	props: {
		audioTracks: {
			type:    Array,
			default: () => [],
		},
		currentAudioTrackId: {
			type:    Number,
			default: 0,
		},
		controlSize: {
			type:     String,
			required: true,
		},
		forceShow: {
			type:    Boolean,
			default: false,
		},
		isMobileWebApp: {
			type:    Boolean,
			default: false,
		},
		isTouchDevice: {
			type:    Boolean,
			default: false,
		},
	},
	data() {
		return {
			showMenu: false,
		};
	},
	computed: {
		menuProps() {
			return {
				variant:             this.isMobileWebApp ? 'mobile' : 'default',
				audioTrackOptions:   this.audioTracks,
				currentAudioTrackId: this.currentAudioTrackId,
			};
		},
		tooltip() {
			if (this.isTouchDevice || this.showMenu) return {};

			return {
				content: this.$I18n.trans('view_mode.language'),
				classes: 'tooltip',
				trigger: 'hover',
			};
		},
	},
	methods: {
		handleClick(event) {
			if (this.$refs.audioTracksMenu?.contains(event.target)) {
				return;
			}
			this.showMenu = !this.showMenu;
			this.$emit('menu-toggle', this.showMenu);

			if (this.showMenu) {
				this.$emit('opened');

				const interval = setInterval(() => {
					if (this.showMenu) {
						this.$emit('still-open');
					} else {
						clearInterval(interval);
					}
				}, 2000);
			}

			if (this.$refs.playerControlsAudioTracksMenu) {
				this.$refs.playerControlsAudioTracksMenu.resetToMainMenu();
			}
		},
		handleClose() {
			this.showMenu = false;
			this.$emit('menu-toggle', false);
			this.$emit('close');
		},
		closeMenu() {
			if (this.showMenu) {
				this.showMenu = false;
				this.$emit('menu-toggle', false);
				this.$emit('close');
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.player-controls__button {
	position: relative;
	display: flex;
	color: var(--color-white);
	line-height: 1;
	cursor: pointer;

	:deep(.player-controls.-dark) & {
		color: var(--color-black);
	}
}

.player-controls__menu {
	position: absolute;
	display: flex;
	flex-direction: column;
	visibility: hidden;
	opacity: 0;
	transition: visibility 0.2s, opacity 0.2s ease-in-out;
	text-align: center;
	bottom: 32px;
	right: -16px;

	&.is-visible {
		visibility: visible;
		opacity: 1;
	}
}
</style>
