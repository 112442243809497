<template>
	<div
		v-if="$store.state.events[storeId].items.length"
		class="l-content-section js-keynav-section"
		:class="{
			'-reduced-spacing': laneType === ArenaLaneType.LIVE_AND_UPCOMING,
			'c-lane': laneType === ArenaLaneType.SPORT,
			'-highlighted': highlighted,
		}"
	>
		<template v-if="laneType === ArenaLaneType.SPORT">
			<a
				class="c-lane__card"
				:href="moreLinkComputed"
			>
				<div class="c-lane__card-icon">
					<svg>
						<use :href="albumIcon" />
					</svg>
				</div>
				<h5 class="c-lane__card-title">
					<span>{{ title }}</span>
					<Icon
						icon-name="right-arrow"
						size="sm"
					/>
				</h5>
			</a>
		</template>
		<template v-else>
			<SectionTitle
				v-if="title"
				:title="title"
				:more-link="subtitle ? null : moreLinkComputed"
				:more-text="subtitle ? null : $I18n.trans('commons.show_more')"
				:more-link-active="!subtitle && (($store.getters.events(storeId) || []).length > 3) && !$arenaConfig.isSmartTv"
			/>
			<SectionTitle
				v-if="subtitle"
				type="sm"
				:title="subtitle"
				:more-link="moreLinkComputed"
				:more-text="$I18n.trans('commons.show_more')"
				:more-link-active="(($store.getters.events(storeId) || []).length > 3) && !$arenaConfig.isSmartTv"
			/>
		</template>
		<div
			class="c-lane__content"
		>
			<Carousel
				ref="carousel"
				:cells="$store.getters.events(storeId)"
			>
				<template #cell="{cell, isDragging, index}">
					<EventCard
						v-bind="EventCardProps(cell, {
							showEventTags: laneType === ArenaLaneType.SPORT ? false : $arenaConfig.showEventCardTags,
							large: highlighted,
							hidePaymentRestriction:	hasAccessToEvent(cell)
						})"
					/>
					<AdCard
						v-if="ad && index + 2 === ad.position"
						:labels="labels"
						:clickable="!isDragging"
						:large="highlighted"
						:ad="ad"
						@error-loading="handleLoadingErrorAd"
					/>
				</template>
			</Carousel>
		</div>
	</div>
</template>

<script>
import Icon from '@shared/components/Icon';
import Carousel from '@js/components/Carousel';
import SectionTitle from '@js/components/SectionTitle';
import AdCard from '@js/components/AdCard';
import EventCard from '@shared/components/EventCard/EventCard';
import { EventCardProps } from '@shared/components/EventCard/EventCardProps';
import { ArenaLaneType } from '@shared/enums/ArenaLaneType';
import laroute from '@laroute';
import { mapGetters } from 'vuex';

export default {
	name:       'EventLane',
	components: { Carousel, SectionTitle, Icon, EventCard, AdCard },
	props:      {
		laneType: {
			type:      String,
			default:   ArenaLaneType.ALBUM,
			validator: (value) => Object.values(ArenaLaneType).includes(value),
		},
		storeId: {
			type:     String,
			required: true,
		},
		albumId: {
			type:    Number,
			default: null,
		},
		albumIcon: {
			type:    String,
			default: null,
		},
		title: {
			type:    String,
			default: null,
		},
		subtitle: {
			type:    String,
			default: null,
		},
		moreLink: {
			type:    String,
			default: null,
		},
		ad: {
			type:    Object,
			default: null,
		},
		highlighted: {
			type:    Boolean,
			default: false,
		},
		labels: {
			type:    Array,
			default: () => [],
		},
	},
	data() {
		return {
			EventCardProps,
			laroute,
			ArenaLaneType,
		};
	},
	computed: {
		...mapGetters({
			hasAccessToEvent: 'user/hasAccessToEvent',
		}),
		moreLinkComputed() {
			if (this.moreLink) {
				return this.moreLink;
			} else if (this.laneType === ArenaLaneType.ALBUM || this.laneType === ArenaLaneType.SPORT) {
				return laroute.route('arena.album', { album: this.albumId, slug: $Helpers.slugify(this.title) });
			}
			return null;
		},
	},
	methods: {
		handleLoadingErrorAd() {
			this.$refs.carousel?.rerender();
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.c-lane {
	@include size(md-up) {
		display: flex;
		flex-direction: row;
	}
}

.c-lane__card {
	display: flex;
	margin-bottom: $sp3;

	@include size(md-up) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 160px;
		height: calc(($carousel-card-width - ($carousel-card-overflow * 2)) / 16) * 9;
		background-color: var(--color-grey-900);
		border-radius: $border-radius--sm;
		margin-right: ($carousel-card-overflow * 2);
		transition: background-color $trans-time-fast;
		margin-bottom: 0;

		&:focus,
		&:hover {
			background-color: var(--color-hype-yellow);
		}

		/* stylelint-disable */
		body.-t-oeoc & {
			&:focus,
			&:hover {
				background-color: var(--color-grey-700);
			}
		}
		/* stylelint-enable */
	}
}

.c-lane__card-icon {
	width: 32px;
	height: 32px;
	margin-right: $sp3;

	svg { // stylelint-disable-line selector-max-type
		width: 100%;
		height: 100%;
	}

	@include size(md-up) {
		width: 64px;
		height: 64px;
		margin-bottom: $sp4;
		margin-right: 0;
	}
}

.c-lane__card-title {
	display: flex;
	align-items: center;
	padding-left: $sp3;
	padding-right: $sp3;
	text-align: center;

	span { // stylelint-disable-line selector-max-type
		margin-right: $sp2;
	}

	@include size(sm-down) {
		font-size: 20px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list

		span { // stylelint-disable-line selector-max-type
			margin-left: $sp2;
		}
	}
}

.c-lane__content {
	flex-grow: 1;
}
</style>
