<template>
	<div class="sports">
		<SectionTitle
			v-if="!$arenaConfig.isStvFsg"
			:title="$I18n.trans_choice('commons.sport', 2)"
		/>
		<div class="sports__grid">
			<a
				v-for="sport in sports"
				:key="sport.id"
				class="sports__link"
				:href="getSportUrl(sport)"
			>
				<div class="sports__icon">
					<svg>
						<use :href="sport.imgUrl ? sport.imgUrl : 'images/sport-icons/fallback.svg#icon'" />
					</svg>
				</div>
				<div class="sports__name">
					{{ sport.name }}
				</div>
			</a>
		</div>
	</div>
</template>

<script>
import SectionTitle from '@js/components/SectionTitle';
import laroute from '@laroute';

export default {
	name:       'Sports',
	components: { SectionTitle },
	props:      {
		sports: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	data() {
		return { laroute };
	},
	computed: {
		sortedSports() {
			return [...this.sports].sort((a, b) => a.name.localeCompare(b.name));
		},
	},
	methods: {
		getSportUrl(sport) {
			return sport.link ?? (laroute.route('arena.album', { album: sport.id, slug: $Helpers.slugify(sport.name) }));
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.sports {
	margin-top: $sp8;
	margin-bottom: $sp8;
}

.sports__grid {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	column-gap: $sp4;
	row-gap: $sp4;

	@include size(sm-up) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
		column-gap: $sp5;
		row-gap: $sp5;
	}

	@include size(md-up) {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}

	@include size(lg-up) {
		grid-template-columns: repeat(5, minmax(0, 1fr));
	}

	@include size(xl-up) {
		grid-template-columns: repeat(7, minmax(0, 1fr));
	}
}

.sports__link {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 160px;
	text-align: center;
	padding: $sp4;
	background-color: var(--color-grey-900);
	transition: background-color $trans-time-fast;

	&:focus,
	&:hover {
		background-color: var(--color-hype-yellow);
	}

	@include size(sm-up) {
		height: 180px;
	}

	/* stylelint-disable */
	body.-t-oeoc & {
		&:focus,
		&:hover {
			background-color: var(--color-grey-700);
		}
	}

	body.-t-light & {
		background-color: transparent;
	}
	/* stylelint-enable */
}

.sports__icon {
	width: $sp8;
	height: $sp8;
	margin-bottom: $sp4;

	svg { // stylelint-disable-line selector-max-type
		width: 100%;
		height: 100%;
	}

	/* stylelint-disable */
	body.-t-light & {
		color: var(--color-hype-yellow);
	}
	/* stylelint-enable */
}

.sports__name {
	transition: color $trans-time-fast;

	/* stylelint-disable */
	body.-t-light .sports__link:focus &,
	body.-t-light .sports__link:hover & {
		color: var(--color-hype-yellow);
	}
	/* stylelint-enable */
}

</style>
